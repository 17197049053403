import './App.css';
import React from "react";
// import './App.css';
import { Link, Route, BrowserRouter, Routes } from "react-router-dom";

import Main from "./components/Main";
import Mall from "./components/page/Mall";
import Community from "./components/page/Community";
import CustomerCenter from "./components/page/CustomerCenter";
import Header from './components/Header';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/mall" element={<Mall />} />
          <Route path="/community" element={<Community />} />
          <Route path="/customerCenter" element={<CustomerCenter />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
