import React, { Component } from "react";
import "../../css/main.css";
import nnmall from "../../img/mall/nnmall.jpg";
import mallmain from "../../img/mall/mall-main.png";
import about1 from "../../img/mall/m-about1.png";
import about2 from "../../img/mall/m-about2.png";
import about3 from "../../img/mall/m-about3.png";
import arrow from "../../img/mall/m-arrow.png";
import pro1 from "../../img/mall/m-pro1.png";
import pro2 from "../../img/mall/m-pro2.png";
import pro3 from "../../img/mall/m-pro3.png";
import check from "../../img/mall/m-check.png";
import icon from "../../img/mall/sig-icon.png";
class Mall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
        this.formRef = React.createRef();
    }

    render() {
        return (
            <>
                <div className="nnmarket-mall">
                    <div className="container">
                        <div className="inner">
                            <img src={mallmain} className="mall-main" />


                        </div>
                    </div>
                    <div className="container">
                        <div className="inner">
                            <div className="m-Box m-about">
                                <div className="m-subBox bold m-txt-subBox">
                                    <div className="txt-80">냠냠몰이란?</div>
                                    <p className="title-txt50">냠냠몰은 이런 서비스입니다!</p>
                                    <div className="high-txt">
                                        <p className="txt-36">냠냠몰 배달은 냠냠박스 라이더가 빠르게 배달</p>
                                        <div className="highlight">&nbsp;</div>
                                    </div>
                                </div>
                                <div className="m-Content">
                                    <div className="txt-36">&lt; 냠냠몰 주문방법 &gt;</div>
                                    <div className="m-iconBox">
                                        <img src={about1} className="m-icon1" />
                                        <img src={arrow} className="m-arrow" />
                                        <img src={about2} className="m-icon1" />
                                        <img src={arrow} className="m-arrow" />
                                        <img src={about3} className="m-icon1" />
                                    </div>
                                </div>
                                <div className="m-Content">
                                    <div className="m-subContent">
                                        <p className="txt-24">배달앱에서<br /> 냠냠몰 제품 주문</p>
                                        <p></p>
                                        <p className="txt-24"><p className="bold">냠냠박스 라이더</p>가<br /> 픽업한 후 바로 배달</p>
                                        <p></p>
                                        <p className="txt-24 bold">빠른 배달완료!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container m-sig-container">
                        <div className="inner">
                            <div className="m-Box m-sig">
                                <div className="m-subBox m-txt-subBox ">
                                    <p className="title-txt50">냠냠몰 시그니처 메뉴도 배달!</p>
                                    <div className="high-txt">
                                        <p className="txt-36">신선한 재료, 특제 소스로 개발된 신개념 토스트와<br />
                                            <p className="highlight">&nbsp;</p>
                                            국내 최고급 원두로 내린 커피를 집에서 만나보세요</p>
                                        <p className="highlight">&nbsp;</p>
                                    </div>
                                </div>
                                <div className="m-subBox bold">
                                    <div className="sig-content">
                                        <p className="txt-48">#BEST&nbsp;</p>
                                        <p className="txt-48">#냠스트</p>
                                        <p className="txt-48">#냠커피</p>
                                    </div>
                                    <div className="sig-content1">
                                        <img src={icon} className="m-sig-icon" />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="container">
                        <div className="inner">
                            <div className="m-Box m-pro">
                                <div className="m-subBox bold m-txt-subBox">
                                    <p className="title-txt50">편의점 모든 물품 배달가능!</p>
                                    <div className="high-txt">
                                        <p className="txt-36">필요 물품들을 쉽고 간편하게 구매해보세요.
                                            <p className="highlight">&nbsp;</p>
                                        </p>
                                    </div>
                                </div>
                                <div className="m-Content">
                                    <div className="m-iconBox">
                                        <img src={pro1} className="m-pro1" />
                                        <img src={pro2} className="m-pro1" />
                                        <img src={pro3} className="m-pro1" />
                                    </div>
                                </div>
                                <div className="m-Content">
                                    <div className="m-subContent m-proSub">
                                        <p className="txt-24">생필품</p>
                                        <p className="txt-24">간편 한끼</p>
                                        <p className="txt-24">각종 디저트</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="inner">
                            <img src={nnmall} className="nnmall" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="inner">
                            <div className="m-Box m-check">
                                <div className="m-subBox m-txt-subBox">
                                    <div className="high-txt">
                                        <p className="txt-36 bold">"냠냠몰" 이런 분들께 추천드립니다~
                                            <p className="highlight">&nbsp;</p>
                                        </p>
                                    </div>
                                </div>
                                <div className="m-Content">
                                    <div className="m-subContent">
                                        <img src={check} className="check" />
                                        <p className="txt-24">급하게 생필품이 필요하신 분들</p>
                                    </div>
                                    <div className="m-subContent">
                                        <img src={check} className="check" />
                                        <p className="txt-24">냠냠몰 시그니처 메뉴를 맛보고 싶으신 분들</p>
                                    </div>
                                    <div className="m-subContent">
                                        <img src={check} className="check" />
                                        <p className="txt-24">빠른 배달을 원하시는 분들</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="inner">
                            <div className="m-Box m-ft">
                                <div className="m-subBox">
                                    <p className="txt-48 white">여러분을 놀라게 냠냠몰<br />
                                        멋지게 준비중이니 지켜봐주세요!</p>

                                </div>
                            </div>

                        </div>

                    </div>


                </div>

            </>
        );
    }
}
export default Mall;
