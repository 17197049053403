
import React from "react";
import "../../css/community.css";
import { Popover } from 'antd';
import "antd/dist/antd.css";
import "../../css/community_m.css";
import { Link } from "react-router-dom";

class Community extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            eventList: [
                require("../../img/community/community-event-1.jpg"),
                // require("../../img/event-사진.png")
                // require("../../img/event-사진.png"),
            ],
            snsList: [
                {img: require("../../img/community/community-sns-1.jpg"), link: 'https://www.instagram.com/p/CaUD7PXPFmX/'},
                {img: require("../../img/community/community-sns-2.jpg"), link: 'https://www.instagram.com/p/CZL7sibPJ25/'},
                {img: require("../../img/community/community-sns-3.jpg"), link: 'https://www.instagram.com/p/CY-gfebPj95/'},
            ],
            openList: [
                {
                    path: require("../../img/community/community-open-1.png"),
                    title: "냠냠마켓",
                    content: "인천 송도점 OPEN!"
                },
                {
                    path: require("../../img/community/community-open-2.png"),
                    title: "냠냠마켓",
                    content: "인천 영종도점은 준비중이에요!"
                },
                {
                    path: require("../../img/community/community-open-3.png"),
                    title: "냠냠마켓",
                    content: "인천 검단점은 준비중이에요!"
                },
            ],
        };
        this.formRef = React.createRef();


    }

    render() {

        const content = (
            <div>
                <div className="popover-title"></div>
                <div className="popover-content">
                    냠냠마켓이 인천 송도점에서!<br />
                    오픈을 하였습니다!<br />
                    많은 관심 부탁드립니다~!
                </div>
            </div>
        );

        return (
            <div className="community">
                <div className="com-main">
                    <div className="com-txt1">
                        EVENT
                        {/* <div className="com-txt2">
                            {"전체보기 >"}
                        </div> */}
                    </div>

                    <div className="com-line" />
                    <a href="https://blog.naver.com/nnmarket" target='_blank'>
                        <div className="event-image">
                            {
                                this.state.eventList.map((row) => {
                                    return (<div><img src={row} /></div>)
                                })
                                /* <div><img src={require("../../img/event-사진.png")}/></div> 
                                <div><img src={require("../../img/event-사진.png")}/></div>
                                <div><img src={require("../../img/event-사진.png")}/></div>   */}
                        </div>
                    </a>
                </div>

                <div className="com-main">
                    <div className="com-txt1">
                        SNS
                        {/* <div className="com-txt2">
                            {"전체보기 >"}
                        </div> */}
                    </div>
                    <div className="com-line" />
                    <div className="com-img">
                        {
                            this.state.snsList.map((row) => {
                                return (<a href={row.link} target='_blank'><div><img src={row.img} /></div></a>)
                            })

                        }
                        {/* <div><img src={require("../../img/event1.png")}/></div>
                        <div><img src={require("../../img/event2.png")}/></div> 
                        <div><img src={require("../../img/event3.png")}/></div>  */}

                    </div>
                </div >

                <div className="com-main">
                    <div className="com-txt1">
                        OPEN
                        {/* <div className="com-txt2">
                            {"전체보기 >"}
                        </div> */}
                        <div className="com-line" />

                        <div className="com-img">
                            {
                                this.state.openList.map((row) => {
                                    const content = (
                                        <div>
                                            <div className="popover-title">{row.title}</div>
                                            <div className="popover-content">
                                                {row.content.split('\n').map(line => {
                                                    return (<span>{line}<br /></span>)
                                                })}
                                            </div>
                                        </div>
                                    );
                                    return (<div>
                                        <Popover content={content} overlayClassName="popOver">
                                            <img className="img" src={row.path} />
                                        </Popover>
                                    </div>)
                                })
                            }
                            {/* <div>
                                <Popover content={content} overlayClassName="popOver">
                                    <img src={require("../../img/open사진.png")}/>
                                </Popover>      
                            </div> 
                            <div>
                                <Popover content={content} overlayClassName="popOver">
                                    <img src={require("../../img/open사진.png")}/>
                                </Popover>      
                            </div> 
                            <div>
                                <Popover content={content} overlayClassName="popOver">
                                    <img src={require("../../img/open사진.png")}/>
                                </Popover>      
                            </div>  */}
                        </div>
                    </div>
                </div>
            </div >





        );
    }
}
export default Community;
