import React, { Component } from "react";
import "../css/main.css";
import { Link, } from "react-router-dom";
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0
        };
    }

    setSelected = (num) => {
        this.setState({
            selected: num
        })
    }

    render() {

        return (
            <>
                <div className="top-header">
                    <div className="top-container">
                        <div className="top-navi txt-24">
                            <div className="top-navi-title" onClick={() => this.setSelected(0)}>
                                <Link to="/" >
                                    냠냠마켓
                                </Link>
                            </div>
                        </div>
                        <div class="top-navi2 txt-24">
                            {/* <div class="top-navi-txt">
                                <Link to="/mall" >
                                    냠냠몰
                                </Link>
                            </div> */}

                            <div className="top-navi-txt-box">
                                <div class={this.state.selected == 1 ? "top-navi-txt-after" : "top-navi-txt"}
                                    onClick={() => this.setSelected(1)}
                                >
                                    <Link to="/community" >
                                        커뮤니티
                                    </Link>
                                </div>

                                <div class={this.state.selected == 2 ? "top-navi-txt-after" : "top-navi-txt"}
                                    onClick={() => this.setSelected(2)}
                                >
                                    <Link to="/customerCenter" >
                                        고객센터
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </>
        );
    }
}
export default Header;
