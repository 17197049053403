import React, { Component } from "react";
import "../../css/CustomerCenter.css";
import "../../css/main.css";
import "../../css/CustomerCenter_m.css";
import Fade from 'react-reveal/Fade';


class CustomerCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
        this.formRef = React.createRef();
    }

    render() {
        const settings = {
            dots: true,
            speed: 500,
            arrows: false,
            // slidesToShow: isMobile ? 1 : 3,
            slideToShow: 3,
            slidesToScroll: 1,
            // afterChange: function (index) {
            //   console.log(
            //     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            //   );
            // },
        };

        return (
            <>
                <div className="CustomerCenter">
                    <div className="CustomerCenter-container">

                        <div className="CustomerLeft">
                            <Fade bottom delay={205} duration={960}>
                                <div>
                                    <div className="CustomerCenter-box">
                                        <div>무엇을</div>
                                        <div>도와드릴까요?</div>
                                        <div className="CustomerCenter-line"></div>
                                    </div>
                                    <div className="CustomerCenter-call">
                                        <div>고객센터 전화문의</div>
                                        <div>이용 시 궁금한 점이 있으신가요?<br />
                                            정성을 다해 상담해 드리겠습니다.<br />
                                            상담시간 : 평일 오전 10시~18시 운영
                                        </div>
                                    </div>
                                </div>
                            </Fade>

                            <div className="CustomerCenter-number">
                                &nbsp;032-832-1020&nbsp;&nbsp;&gt;
                            </div>
                        </div>

                        <div className="CustomerCenter-txt">
                            <div>nyam</div>
                            <div>nyam</div>
                            <div>market</div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default CustomerCenter;
