import React, { Component } from "react";
import "../css/main.css";
import "../css/main_m.css";
import { Link } from "react-router-dom";
// import main from "../img/main_img.png"
import nm1 from "../img/about-nm1.png"
import nm2 from "../img/about-nm2.png"
import signm from "../img/sig-nm.png"
import event1 from "../img/event1.png"
import event2 from "../img/event2.png"
import event3 from "../img/event3.png"
import Slider from "react-slick";
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';





class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addEntryVisible: false,
      expandImg: 1
    };
    this.formRef = React.createRef();
  }






  more = () => {
    this.setState({
      width: "50%",
      height: "50%",
      width2: "25%",
      height2: "50%",
      width3: "25%",
      height3: "50%",
      display: false,
      expandImg: 1
    })
  }
  more2 = () => {
    this.setState({
      width: "25%",
      height: "50%",
      width2: "50%",
      height2: "50%",
      width3: "25%",
      height3: "50%",
      expandImg: 2
    })
  }
  more3 = () => {
    this.setState({
      width: "25%",
      height: "50%",
      width2: "25%",
      height2: "50%",
      width3: "50%",
      height3: "50%",
      expandImg: 3
    })
  }

  render() {
    const settings = {
      dots: true,
      speed: 500,
      arrows: true,
      infinite: true,
      // slidesToShow: isMobile ? 1 : 3,
      slideToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      afterChange: function (index) {
        console.log(
          `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        );
      },
    };
    const value = this.state.value;
    const expandImg = this.state.expandImg;

    return (
      <>
        <div>

          <div className="main">
            <div className="main-box">
              <Fade bottom cascade delay={230} duration={840}>
                <div className="main-txt">
                  <div>nyamnyam market</div>
                  <div>nyamnyam market</div>
                  <div>nyamnyam market</div>
                </div>
              </Fade>

              <div className="main-btn"
                onClick={() => alert("냠냠몰은 현재 서비스 준비 중입니다.")}>
                <div className="main-img-btn">
                  <img src={require('../img/but-Google.png')}></img>
                  <div>Google play</div>
                </div>
                <div className="main-img-btn">
                  <img src={require('../img/but-App.png')}></img>
                  <div>App Store</div>
                </div>
              </div>

              <div className="main-tag">
                <div>냠냠마켓 오픈예정!</div>
                <div>#신개념 #멀티스토어</div>
              </div>

            </div>
          </div>



          <div className="market-about">
            <div className="market-container">

              <Fade bottom delay={205} duration={960}>
                <div className="market-title">
                  신개념 멀티 스토어<br />
                  X<br />
                  냠냠마켓<br />
                </div>
              </Fade>

              <div className="market-box">
                <img
                  className="market-box-img"
                  src={require('../img/market-about-logo.png')}
                />
                <Fade bottom delay={205} duration={960}>
                  <div className="market-txt">
                    <div className="market-txt-line" />
                    <div>
                      신개념 멀티 스토어 냠냠마켓은 <br />
                      MZ세대의 시각에 맞춰 <br />
                      보는 즐거움, 맛보는 즐거움을 <br />
                      누릴 수 있는 공간으로 방문해주시는 <br />
                      모든 분들께 특별한 경험을 선사합니다.
                    </div>
                  </div>
                </Fade>

                <div style={{ display: 'flex' }}>
                  <Fade bottom delay={105} duration={800}>
                    <div className="market-sub-txt">
                      <div className="market-sub-txt2">
                        냠냠마켓만의 <br />
                        차별화
                      </div>
                      <div className="market-sub-txt3">
                        <div className="market-sub-txt3-line"></div>
                        <div>
                          냠냠마켓은 기존 편의점의 틀을 깬 <br />
                          신개념 멀티 스토어로
                        </div>
                        <div>
                          {/* 전문 조리사가 직접 토스트와 <br /> */}
                          {/* 3단계 테스팅을 걸쳐 채택된 <br />
                          고급 원두커피를 제공함으로써 <br />
                          고객님들께 차별화된 가치를 선사합니다. */}
                          MZ세대의 가치 있는 소비를 위해<br />
                          다양한 시장 조사와 까다로운 상품<br />
                          선정을 통해 트렌드 한 고객님들께<br />
                          차별화된 가치를 선사합니다.
                        </div>
                      </div>
                    </div>
                  </Fade>

                  <div className="market-sub-img">
                    <div>
                      <img src={require('../img/market-about-img-1.png')} />
                    </div>
                    <div>
                      <img src={require('../img/market-about-lmg-2.png')} />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


          {/* <div className="container-background">
            <div className="container pro-container">
              <div className="inner">

                <div className="pro-box">
                  <div
                    className={expandImg == 1 ? "pro-sub-expand" : "pro-sub"}
                    style={{ width: this.state.width }} >
                    <div className="pro-txtBox">

                      {expandImg == 1 ?
                        <p className="txt-36 white bold">
                          <div className="txt-36-line"></div>
                          냠냠토스트는 냠냠마켓 대표 시그니처 상품으로
                          철저한 위생관리 안에 신선한 재료 사용은 물론
                          여러 번의 요리연구와 테스트를 거처 만든 토스트로
                          한번 먹으면 계속 생각나는 매력을 가진 상품입니다!
                          <div className="txt-80-box">
                            <p className="txt-80 white txt1">#냠냠토스트</p>
                          </div>
                        </p>
                        :
                        <div className="more" onClick={this.more} >
                          <div className="txt-80-1 white">#냠냠토스트</div>
                          <p>상세보기&nbsp;&nbsp;&nbsp;&gt;</p>
                        </div>
                      }

                    </div>
                  </div>

                  <div className={expandImg == 2 ? "pro-sub2-expand" : "pro-sub2"}
                    style={{ width: this.state.width2 }}>
                    <div className="pro-subBox2">

                      {expandImg == 2 ?
                        <p className="txt-36 white bold">
                          <div className="txt-36-line"></div>
                          3단계 테스팅을 걸쳐 원두본연의 묵직함을 그대로 담아
                          고소한 맛과 향의 균형이 잡힌 최상급의 커피를 제공합니다.
                          <div className="txt-80-2-box">
                            <p className="txt-80-2 white txt1">#냠냠커피</p>
                          </div>
                        </p>
                        :
                        <div className="more" onClick={this.more2}>
                          <div className="txt-80-2 white">#냠냠커피</div>
                          <p>상세보기&nbsp;&nbsp;&nbsp;&gt;</p>
                        </div>
                      }
                    </div>

                  </div>

                  <div className={expandImg == 3 ? "pro-sub3-expand" : "pro-sub3"}
                    style={{ width: this.state.width3 }} >
                    <div className="pro-subBox3">

                      {expandImg == 3 ?
                        <p className="txt-36 white bold">
                          <div className="txt-36-3-line"></div>
                          <p>
                            포장마차에서 먹었던 추억의 #꼬불이어묵
                            매일 진한 육수로 우려내 깊고 진한 맛을 느끼실 수 있습니다.
                          </p>
                          <div className="txt-80-3-box">
                            <p className="txt-80-3 white txt1">#냠냠어묵</p>
                          </div>
                        </p>
                        :
                        <div className="more" onClick={this.more3}>
                          <div className="txt-80-3 white">#냠냠어묵</div>
                          <p>상세보기&nbsp;&nbsp;&nbsp;&gt;</p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


          <div className="mall-about">
            <div className="mall-box">

              <Fade bottom delay={205} duration={960}>
                <div className="mall-title">
                  <div>
                    냠냠마켓을 <br />
                    직접가기 <br />
                    어렵다면?
                  </div>
                  <div className="mall-title-line"></div>
                </div>

              </Fade>
              <div className="mall-title-2">
                nyam <br />
                nyam mall
              </div>
              <div className="mall-sub">
                <div className="mall-sub-img">
                  <div>
                    *냠냠몰은 현재 <br />
                    &nbsp;&nbsp;서비스 준비 중입니다.
                  </div>
                  <div>
                    <img src={require('../img/mall-sub-img.png')} />
                  </div>
                </div>

                <Fade bottom delay={205} duration={960}>
                  <div className="mall-sub-txt">
                    <div>냠냠몰은 이런 서비스입니다.</div>
                    <div className="mall-sub-txt-line"></div>
                    <div>
                      냠냠몰 배달은 냠냠박스 라이더가 <br />
                      빠르게 배달해 드립니다!
                    </div>
                  </div>
                </Fade>

              </div>


              <div className="mall-order">
                <Fade bottom delay={205} duration={960}>
                  <div className="mall-order-txt">
                    <div>
                      냠냠몰<br />
                      주문방법
                    </div>
                    <div className="mall-order-txt-line"></div>
                  </div>
                </Fade>

                <div className="order-icon">
                  <Flip left delay={190} duration={1600}>
                    <div className="order-icon-1">
                      <div>&#9312;</div>
                      <div> <img src={require('../img/order-icon-1.png')} /> </div>
                      <div>
                        냠냠몰앱에서 <br />
                        제품 주문
                      </div>
                    </div>
                  </Flip>

                  <Flip left delay={190} duration={1600}>
                    <div className="order-icon-2">
                      <div>&#9313;</div>
                      <div> <img src={require('../img/order-icon-2.png')} /> </div>
                      <div>
                        <strong>냠냠박스 라이더</strong>가<br />
                        픽업 한 후 바로 배달
                      </div>
                    </div>
                  </Flip>

                  <Flip left delay={190} duration={1600}>
                    <div className="order-icon-3">
                      <div>&#9314;</div>
                      <div> <img src={require('../img/order-icon-3.png')} /> </div>
                      <div>빠른 배달완료!</div>
                    </div>
                  </Flip>
                </div>

              </div>

            </div>
          </div>



          <div className="market-opening">
            <div className="opening-title">
              <div className="opening-title-txt">

                <Fade bottom delay={205} duration={960}>
                  <div>
                    <div>
                      냠냠마켓만의 <br />
                      입점 서비스
                    </div>
                    <div className="opening-title-txt-line"></div>
                    <div>
                      라이프 스타일 멀티 스토어
                    </div>
                    <div>
                      "일상 속 다양한 즐거움을 맛보다."라는 <br />
                      브랜드 비전으로 빠르게 변화하는 <br />
                      MZ세대에 먹는 즐거움, 보는 즐거움 <br />
                      다양한 즐거움을 선사하고자 합니다.
                    </div>
                  </div>
                </Fade>

                <div className="opening-btn"
                  onClick={() => {
                    // this.setState({ addEntryVisible: true })
                    alert('032-832-1020으로 문의해주세요')
                    }}>
                  <div>입점신청&nbsp;&nbsp;&nbsp;&gt;</div>
                </div>
              </div>
              <img className="opening-img" src={require('../img/opening-img.png')} />
            </div>
          </div>

          <div className="container sns-container">
            <div className="inner">
              <div className="about">

              </div>
            </div>
          </div>



          {/* 입점신청 모달 */}
          {this.state.addEntryVisible && (
            <>
              <div className="opening-container"></div>
              {/* // onClick={() => this.setState({ addEntryVisible: false })} */}
              <div className="addEntry-container">

                <div className="addEntry-title">
                  <div className="addEntry-btn"
                    onClick={() => this.setState({ addEntryVisible: false })}>
                    <img src={require('../img/addEntry-btn.png')} />
                  </div>

                  <div className="addEntry-title-main">
                    <div className="addEntry-title-txt desk">
                      <div>냠냠마켓 <br />입점 신청서</div>
                      <div className="addEntry-title-line"></div>
                      <div>
                        냠냠마켓과 함께 성장할 파트너님<br />
                        홍보와 판매가 걱정이신 파트너님<br />
                        오프라인 공간이 필요한 파트너님<br />
                        냠냠마켓이 기다리겠습니다.
                      </div>
                      <div className="addEntry-title-btn desk">
                        제출하기&nbsp;&nbsp;&nbsp;&gt;
                      </div>
                    </div>

                    <div className="addEntry-inputform">
                      <div>회사명 (브랜드) <br /> <input type="text" /></div>
                      <div>전화번호 <br /> <input type="tel" /></div>
                      <div>담당자 성함 <br /> <input type="text" /></div>
                      <div>이메일 <br /> <input type="email" /></div>
                      <div>사이트 URL <br /> <input type="url" /></div>
                    </div>

                    <div className="addEntry-title-btn mobile" >
                      제출하기&nbsp;&nbsp;&nbsp;&gt;
                    </div>



                  </div>
                </div>
              </div>
            </>
          )}

        </div >
      </>
    );
  }
}

export default Main;
